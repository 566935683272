"use client";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../components/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";
import { Input } from "../components/input";
import { Label } from "../components/label";
import { Instagram } from "lucide-react";
import { CardInfo } from "../lib/CardInfo";

export default () => {
  const { id } = useParams();
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [didReset, setDidReset] = useState(false);
  const [contactInfo, setContactInfo] = useState<CardInfo | null>(null);
  const [newInfo, setNewInfo] = useState<CardInfo>({
    name: "",
    phone: "",
    instagram: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCardInfo();
  }, [id]);

  const fetchCardInfo = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/card/${id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch card info");
      }
      const data = await response.json();
      setContactInfo(data);
    } catch (err) {
      setError("Error fetching card info 123");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlock = () => {
    setIsUnlocked(true);
  };

  const handleReset = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/card/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newInfo),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update card info");
      }

      const updatedInfo = await response.json();
      setContactInfo(updatedInfo);
      setDidReset(true);
      setNewInfo({
        name: "",
        phone: "",
        instagram: "",
      });
      setIsUnlocked(false);
    } catch (err) {
      setError("Error updating card info");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewInfo({
      ...newInfo,
      [e.target.name]: e.target.value,
    });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4 font-hand bg-[#FFF0D4]">
        <div className="text-center mt-[30px] font-hand text-[30px]">
          Loading...
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  if (didReset) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4 font-hand bg-[#FFF0D3]">
        <div className="w-full max-w-[800px] flex flex-col">
          <CardHeader>
            <CardTitle className="text-[50px] text-center">
              Card Updated!
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4 flex flex-col items-center">
            <p className="text-center text-gray-700 text-[30px]">
              You can now pass this card to anyone you'd like to share your info
              with. Refresh the page to double check the info and good luck!
            </p>
          </CardContent>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 font-hand bg-[#FFF0D4]">
      <div className="w-full max-w-[800px] flex flex-col">
        <CardHeader>
          <CardTitle className="text-[50px] text-center">
            Random Acts of Dating
          </CardTitle>
          <div className="text-[40px] text-center text-pink-600">
            We're all just hoping for that once in a lifetime meet cute. This
            could be that moment.
          </div>
        </CardHeader>
        <CardContent className="space-y-4 flex flex-col items-center">
          <p className="text-center text-gray-700 text-[30px]">
            R.A.D. is all about getting people to meet in person without putting
            too much pressure on anyone. If you're here, someone thought you
            were cute and wanted to give you their info. Text them if you'd
            like! Or pass it onto the next person.
          </p>
          {!isUnlocked ? (
            <div
              className="px-4 pt-4 pb-2 bg-pink-600 text-white rounded-lg cursor-pointer flex items-center justify-center inline-block"
              onClick={handleUnlock}
            >
              <p className="text-3xl">Unlock Contact</p>
            </div>
          ) : (
            contactInfo && (
              <div className="space-y-4">
                <p className="text-3xl">
                  <strong>First Name:</strong> {contactInfo.name}
                </p>
                <a
                  className="flex items-center text-3xl"
                  href={`tel:${contactInfo.phone.replace(/[^0-9]/gi, "")}`}
                >
                  <strong>Phone Number:</strong>{" "}
                  <p className="text-blue-500 ml-2">{contactInfo.phone}</p>
                </a>
                <a
                  className="flex items-center text-3xl"
                  href={`https://instagram.com/${contactInfo.instagram.replace(
                    "@",
                    ""
                  )}`}
                >
                  <Instagram className="mr-2" />
                  <strong className="mt-2">Instagram:</strong>
                  <p className="text-3xl text-blue-500 ml-2 mt-2">
                    {contactInfo.instagram.replace("@", "")}
                  </p>
                </a>
              </div>
            )
          )}
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <div className="w-full h-px bg-gray-300" />
          <p className="text-gray-600 text-center text-[30px]">
            This card is reusable! Want to pass this card to someone else? Reset
            it with your info:
          </p>
          <div className="space-y-2 w-full">
            <Label htmlFor="firstName">First Name</Label>
            <Input
              id="firstName"
              name="name"
              value={newInfo.name}
              onChange={handleInputChange}
              placeholder="Your first name"
            />
          </div>
          <div className="space-y-2 w-full">
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Input
              id="phoneNumber"
              name="phone"
              value={newInfo.phone}
              onChange={handleInputChange}
              placeholder="Your phone number"
            />
          </div>
          <div className="space-y-2 w-full">
            <Label htmlFor="instagram">Instagram</Label>
            <Input
              id="instagram"
              name="instagram"
              value={newInfo.instagram}
              onChange={handleInputChange}
              placeholder="Your Instagram handle"
            />
          </div>
          <div
            className="px-4 pt-4 pb-2 bg-pink-600 text-white rounded-lg cursor-pointer flex items-center justify-center inline-block"
            onClick={handleReset}
          >
            <div className="text-3xl">Reset Card</div>
          </div>
          <div className="w-full h-px bg-gray-300" />
          <p className="text-gray-600 text-center text-[30px]">
            Want to make your own, new cards? You can print them!
          </p>
          <div
            className="px-4 pt-4 pb-2 bg-pink-600 text-white rounded-lg cursor-pointer flex items-center justify-center inline-block"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <div className="text-3xl">Print More Cards</div>
          </div>
        </CardFooter>
      </div>
    </div>
  );
};
